import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Red`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Orange`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Yellow`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Green`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Blue`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`White`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`raw`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.05`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.32`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`elemental`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.0625`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.15`}</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`Positive affinity boosts your attack by 25%, on the percentage of hits your affinity is`}</li>
      <li parentName="ul">{`Negative affinity drops your attack by 25%, for the percentage of hits you're in the negative by`}</li>
    </ul>
    {
      /*Todo: damage/affinity/sharpness calculator*/
    }
    <p>{`Damage Calculation`}</p>
    <blockquote>
      <p parentName="blockquote">{`90 (`}<strong parentName="p">{`Raw Attack`}</strong>{`)
x 1.05 (`}<strong parentName="p">{`Sharpness multiplier`}</strong>{` for Green-level Sharpness - non-elemental)
x 0.42 (`}<strong parentName="p">{`Combo Move multiplier`}</strong>{` for Hammer's Overhead Smash I attack)
x 0.8 (`}<strong parentName="p">{`Monster Part multiplier`}</strong>{` for Great Izuchi's tail tip against Blunt Damage)
= 31 damage (rounded down)`}</p>
    </blockquote>
    <p>{`Elemental Damage Calculation`}</p>
    <blockquote>
      <p parentName="blockquote">{`42 (`}<strong parentName="p">{`Elemental Attack`}</strong>{` value)
x 1(`}<strong parentName="p">{`Sharpness multiplier`}</strong>{` for Green-level Sharpness - Elemental)
x 0.25 (`}<strong parentName="p">{`Monster Part multiplier`}</strong>{` for Great Izuchi's tail tip against Thunder Damage)
= 10.5 Thunder Damage`}</p>
    </blockquote>
    <p>{`(rage status and quest difficulty also affect damage)`}</p>
    {
      /*|monster|weakness|
      |-------|--------|
      |||*/
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      